@keyframes circle-spinner {
    0% {
        opacity: 1;
        backface-visibility: hidden;
        transform: translateZ(0) scale(1.5,1.5);
    } 100% {
          opacity: 0;
          backface-visibility: hidden;
          transform: translateZ(0) scale(1,1);
      }
}

.circle-spinner div > div {
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #bbbbbb;
    animation: circle-spinner 1.3513513513513513s linear infinite;
}

.circle-spinner div:nth-child(1) > div {
    left: 74px;
    top: 44px;
    animation-delay: -1.1824324324324325s;
}

.circle-spinner > div:nth-child(1) {
    transform: rotate(0deg);
    transform-origin: 80px 50px;
}

.circle-spinner div:nth-child(2) > div {
    left: 65px;
    top: 65px;
    animation-delay: -1.0135135135135136s;
}

.circle-spinner > div:nth-child(2) {
    transform: rotate(45deg);
    transform-origin: 71px 71px;
}

.circle-spinner div:nth-child(3) > div {
    left: 44px;
    top: 74px;
    animation-delay: -0.8445945945945946s;
}

.circle-spinner > div:nth-child(3) {
    transform: rotate(90deg);
    transform-origin: 50px 80px;
}

.circle-spinner div:nth-child(4) > div {
    left: 23px;
    top: 65px;
    animation-delay: -0.6756756756756757s;
}

.circle-spinner > div:nth-child(4) {
    transform: rotate(135deg);
    transform-origin: 29px 71px;
}

.circle-spinner div:nth-child(5) > div {
    left: 14px;
    top: 44px;
    animation-delay: -0.5067567567567568s;
}

.circle-spinner > div:nth-child(5) {
    transform: rotate(180deg);
    transform-origin: 20px 50px;
}

.circle-spinner div:nth-child(6) > div {
    left: 23px;
    top: 23px;
    animation-delay: -0.33783783783783783s;
}

.circle-spinner > div:nth-child(6) {
    transform: rotate(225deg);
    transform-origin: 29px 29px;
}

.circle-spinner div:nth-child(7) > div {
    left: 44px;
    top: 14px;
    animation-delay: -0.16891891891891891s;
}

.circle-spinner > div:nth-child(7) {
    transform: rotate(270deg);
    transform-origin: 50px 20px;
}

.circle-spinner div:nth-child(8) > div {
    left: 65px;
    top: 23px;
    animation-delay: 0s;
}

.circle-spinner > div:nth-child(8) {
    transform: rotate(315deg);
    transform-origin: 71px 29px;
}

.upload-spinner {
    width: 32px;
    height: 32px;
    display: inline-block;
    overflow: hidden;
    background: #ffffff;
}

.circle-spinner {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(0.32);
    backface-visibility: hidden;
    transform-origin: 0 0;
}

.circle-spinner div {
    box-sizing: content-box;
}
