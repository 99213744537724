@tailwind base;

/* Your own custom base styles */
@layer base {
  /* Added to turn off the box shadow around the cursor in in-focus text inputs */
  [type="text"]:focus {
    box-shadow: none !important;
  }
}
/* Start purging... */
@tailwind components;
/* Stop purging. */

/* Your own custom component styles */

/* Start purging... */
@tailwind utilities;
/* Stop purging. */

/* Your own custom utilities */
@layer components {
  .nav-header-text {
    @apply text-base font-medium text-gray-500 hover:text-gray-900 xl:text-xl 2xl:text-2xl min-w-fit
  }

  .faq-dt {
    @apply leading-6 font-medium text-gray-900
  }

  .faq-dd {
    @apply mt-2 text-base text-gray-600
  }

  .prose-text {
    @apply max-w-none prose prose-gray prose-xl prose-a:text-blue-600 visited:text-purple-600
  }

  .static-page-main {
    @apply py-4 px-4 sm:px-6 lg:px-8 bg-gradient-to-b from-gray-100 to-white overflow-hidden min-h-basic
  }

  .static-page-header {
    @apply mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 pb-4 sm:text-4xl
  }

  .static-page-section {
    @apply relative bg-white shadow-lg p-2 sm:p-4 md:rounded-lg md:p-6
  }

  .link-text {
    @apply underline text-blue-600 hover:text-blue-800 visited:text-purple-600
  }

  .disabled-styling {
    @apply disabled:cursor-not-allowed disabled:opacity-25}
}